import { Button, Container, Grid, GridItem, Heading, Stack, Text } from '@carvertical/ui';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouteData } from 'context/RouteDataProvider';
import Page from 'components/common/Page';
import Redirect from 'components/common/Redirect';
import { NS_CONTENT } from 'constants/i18n';
import { FEATURED_ARTICLE_LIMIT } from '../../../constants';
import { useInfiniteArticles } from '../../../hooks';
import type { ResolvedBlogIndexData } from '../../../types';
import { BlogSubmenu } from '../BlogSubmenu';
import { ArticleCard } from '../ArticleCard';
import { FeaturedArticlesCarousel } from './FeaturedArticlesCarousel';
import styles from './BlogIndexPage.module.scss';

const BlogIndexPage = () => {
  const { t } = useTranslation(NS_CONTENT);
  const { routeData, totalEntriesCount = 0 } = useRouteData<ResolvedBlogIndexData>();
  const [fetchOnClickEnabled, setFetchOnClickEnabled] = useState(false);

  const { articles: initialArticles, seo } = routeData;

  const featuredArticles = initialArticles.slice(0, FEATURED_ARTICLE_LIMIT);
  const remainingArticles = initialArticles.slice(1);

  const { articles, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteArticles(fetchOnClickEnabled);

  if (totalEntriesCount === 0) {
    return <Redirect to="/404" />;
  }

  const loadMoreShown =
    typeof hasNextPage === 'undefined' ? initialArticles.length < totalEntriesCount : hasNextPage;

  const shownArticles = [...remainingArticles, ...articles];

  return (
    <Page title={seo?.title} breadcrumbs={false}>
      <BlogSubmenu />

      {!!featuredArticles.length && (
        <section className={styles.featuredArticlesSection}>
          <Container>
            <FeaturedArticlesCarousel articles={featuredArticles} />
          </Container>
        </section>
      )}

      {!!shownArticles.length && (
        <section className={styles.shownArticlesSection}>
          <Container>
            <Stack gap={{ mobileUp: 4, tabletLandscapeUp: 6 }}>
              <Heading as="h1" variant="m">
                {t('allArticles')}
              </Heading>

              <Grid
                columnCount={{
                  mobileUp: 4,
                  tabletPortraitUp: 8,
                  desktopUp: 12,
                }}
                columnGap={4}
                rowGap={{ mobileUp: 4, tabletLandscapeUp: 6 }}
              >
                {shownArticles.map((article) => (
                  <GridItem key={article.title} span={4}>
                    <ArticleCard article={article} />
                  </GridItem>
                ))}
              </Grid>
            </Stack>
          </Container>
        </section>
      )}

      {loadMoreShown && (
        <section className={styles.loadMoreSection}>
          <Container>
            <Stack crossAxisAlign="center">
              <Text variant="s">
                {t('showingArticles', {
                  count: shownArticles.length,
                  total: totalEntriesCount - featuredArticles.length,
                })}
              </Text>

              <Button
                size="l"
                variant="outlined"
                onClick={async () => {
                  setFetchOnClickEnabled(true);
                  await fetchNextPage();
                }}
                loading={isFetching || isFetchingNextPage}
              >
                {t('loadMore')}
              </Button>
            </Stack>
          </Container>
        </section>
      )}
    </Page>
  );
};

export { BlogIndexPage };
